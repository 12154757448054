<template>
	<div class="error-question">
		<navBar @goBack="goBack">
			<span class="title">错题浏览</span>
			<template v-slot:right>
				<div class="acount-question">
					<span>{{ Number(currentIndex) + 1 }}</span>
					/{{ questionGroup.length }}
				</div>
			</template>
		</navBar>

		<div class="proress" v-if="questionGroup.length">
			<div class="con" :style="`width:${((Number(currentIndex) + 1) / questionGroup.length) * 100}%`"></div>
		</div>

		<div class="course-content" v-if="!isLoading">
			<div class="question-box" :class="currentSubject">
				<div class="question-area">
					<div class="area content-area" v-if="questionGroup[currentIndex].yueducontent">
						<div class="con-box" v-html="questionGroup[currentIndex].yueducontent"></div>
					</div>
					<div class="area main-area">
						<div class="box question-body" :class="{ show: showQuestion, hide: !showQuestion }">
							<div class="stem" v-html="questionGroup[currentIndex].stem"></div>
							<div class="sigin-choose" v-if="questionGroup[currentIndex].classtext < 3">
								<ul class="options" id="options" ref="options">
									<li
										v-for="(opt, index) in questionGroup[currentIndex].option"
										:key="index"
										:class="{
											active: questionGroup[currentIndex].userAnswer.includes(index),
											proper:
												(questionGroup[currentIndex].option[index].status == 1 &&
													questionGroup[currentIndex].userAnswer.includes(index)) ||
												(html2string(questionGroup[currentIndex].answer).includes(
													optionsTag[index]
												) &&
													questionGroup[currentIndex].showAnalysis),
											error:
												questionGroup[currentIndex].option[index].status == 2 &&
												questionGroup[currentIndex].userAnswer.includes(index)
										}"
										@click="selectAction(index, questionGroup[currentIndex].classtext)"
									>
										<span v-html="opt.label"></span>
									</li>
								</ul>
							</div>

							<div class="fill-blanks" v-if="questionGroup[currentIndex].classtext == 3">
								<textarea
									class="user-textarea"
									:rows="5"
									v-model="questionGroup[currentIndex].userAnswer"
								/>
							</div>
						</div>
						<div class="box question-operate" v-if="questionGroup[currentIndex].showAnalysis">
							<ul class="result-list">
								<li>
									<span class="label-for">【正确答案】</span>
									<span class="label-text" v-html="questionGroup[currentIndex].answer"></span>
								</li>
								<li>
									<span class="label-for">【您的答案】</span>
									<span class="label-text" v-if="questionGroup[currentIndex].classtext < 3">
										{{ questionGroup[currentIndex].userAnswerTag || '暂未作答' }}
									</span>
									<span class="label-text" v-else>
										{{ questionGroup[currentIndex].userAnswer || '暂未作答' }}
									</span>
								</li>
								<li>
									<span class="label-for">【答案解析】</span>
									<span
										class="label-text"
										v-html="questionGroup[currentIndex].analysis"
										v-if="questionGroup[currentIndex].analysis"
									></span>
									<span class="label-text" v-else>略</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="question-handle">
				<div class="btn" :class="{ disable: currentIndex == 0 }" @click="changeQuestion(1)">上一题</div>
				<div
					class="btn"
					:class="{ disable: currentIndex == questionGroup.length - 1 }"
					@click="changeQuestion(2)"
				>
					下一题
				</div>
				<div class="btn submit-btn" @click="showParseAction">解析</div>
			</div>
		</div>
	</div>
</template>

<script>
import { deepClone, html2string, formatMathJaxImg, formatAddPointWords } from '@/utils/public';
export default {
	name: 'errorQuestion',
	data() {
		return {
			isLoading: true,
			showQuestion: false,
			questionGroup: [],
			questionSourse: [],
			currentIndex: 0,
			currentSubject: '',
			optionsTag: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']
		};
	},
	created() {
		this.initData();
		let subjectName = this.$route.query.subjectName;
		switch (subjectName) {
			case '语文': {
				this.currentSubject = 'yuwen';
				break;
			}
			case '数学': {
				this.currentSubject = 'shuxue';
				break;
			}
			case '英语': {
				this.currentSubject = 'yingyu';
				break;
			}
			case '物理': {
				this.currentSubject = 'wuli';
				break;
			}
			case '化学': {
				this.currentSubject = 'huaxue';
				break;
			}
			case '政治': {
				this.currentSubject = 'zhengzhi';
				break;
			}
			case '历史': {
				this.currentSubject = 'lishi';
				break;
			}
			case '生物': {
				this.currentSubject = 'shengwu';
				break;
			}
			case '地理': {
				this.currentSubject = 'dili';
				break;
			}
			default: {
				this.currentSubject = '';
			}
		}
	},
	methods: {
		deepClone,
		html2string,
		formatAddPointWords,
		formatMathJaxImg,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
			setTimeout(() => {
				this.showQuestion = true;
			}, 200);
		},
		initData() {
			this.$api
				.getErrorBookList({
					subject_id: this.$route.query.errorId,
					rows: 10000000
				})
				.then((res) => {
					let resData = res.data?.data || [];
					let questionList = [];
					resData.forEach((item) => {
						let _item = {
							rightandwrong: item.rightandwrong,
							...item.questioninfo
						};
						questionList.push(_item);
					});

					questionList.forEach((item) => {
						item.option = this.computedOptions(item.option);
						if (item.classtext < 3) {
							item.userAnswer = [];
						} else {
							item.userAnswer = '';
						}
						item.userAnswerTag = '';
						item.showAnalysis = false;
						item.content = this.formatMathJaxImg(item.content);
						item.stem = this.formatMathJaxImg(item.stem);
						item.answer = this.formatMathJaxImg(item.answer);
						item.analysis = this.formatMathJaxImg(item.analysis);

						item.content = this.formatAddPointWords(item.content);
						item.stem = this.formatAddPointWords(item.stem);
						item.answer = this.formatAddPointWords(item.answer);
						item.analysis = this.formatAddPointWords(item.analysis);
					});
					this.isLoading = false;
					this.questionGroup = questionList;
					this.questionSourse = this.deepClone(questionList);
					setTimeout(() => {
						this.setMathJaxText();
					}, 10);
				});
		},

		showParseAction() {
			let currentQUestion = this.questionGroup[this.currentIndex];
			if (currentQUestion.showAnalysis) {
				return false;
			}
			currentQUestion.showAnalysis = true;

			this.$set(this.questionGroup, this.currentIndex, currentQUestion);
			let answer = currentQUestion.answer;
			let userAnswerTag = currentQUestion.userAnswerTag;
			let rightandwrong = Number(currentQUestion.rightandwrong) || 0;

			if (this.html2string(answer) == userAnswerTag) {
				rightandwrong = ++rightandwrong;
			} else {
				rightandwrong = 0;
			}
			let option = this.questionGroup[this.currentIndex].option;
			for (let i = 0; i < option.length; i++) {
				if (answer.includes(this.optionsTag[i])) {
					option[i].status = 1;
				} else {
					option[i].status = 2;
				}
			}
			this.$set(this.questionGroup[this.currentIndex], 'option', option);
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
			this.addNum(rightandwrong);
		},
		addNum(num) {
			this.$api.addErrorQuestion({
				subject_id: this.$route.query.errorId,
				questionbank_id: this.questionGroup[this.currentIndex].id,
				rightandwrong: num
			});
		},
		changeQuestion(n) {
			if (n == 1) {
				if (0 < this.currentIndex) {
					this.currentIndex = --this.currentIndex;
					this.showQuestion = false;
				} else {
					this.currentIndex = 0;
					this.$notify('已经是第一题了！');
					return false;
				}
			} else {
				if (this.currentIndex < this.questionGroup.length - 1) {
					this.currentIndex = ++this.currentIndex;
					this.showQuestion = false;
				} else {
					let that = this;
					that.currentIndex = this.questionGroup.length - 1;

					this.$dialog
						.confirm({
							message: '已是最后一个，再做一次？'
						})
						.then(() => {
							that.currentIndex = 0;
							that.questionGroup = that.deepClone(that.questionSourse);
							setTimeout(() => {
								that.setMathJaxText();
							}, 10);
						}).catch(() => {});

					return false;
				}
			}
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
		},
		selectAction(index, type) {
			let question = this.questionGroup[this.currentIndex];

			if (question.showAnalysis) {
				return false;
			}
			let userAnswer = question.userAnswer || [];

			let idxof = userAnswer.indexOf(index);
			if (type == 1) {
				userAnswer = [];
			}
			if (userAnswer.includes(index)) {
				userAnswer.splice(idxof, 1);
			} else {
				if (type == 1) {
					userAnswer = [index];
				}
				if (type == 2) {
					if (idxof < 0) {
						userAnswer.push(index);
					}
				}
			}
			question.userAnswer = userAnswer.sort();
			let _userAnswerTag = '';
			for (let i = 0; i < question.userAnswer.length; i++) {
				_userAnswerTag = _userAnswerTag + this.optionsTag[question.userAnswer[i]];
			}

			question.userAnswerTag = _userAnswerTag;
			this.$set(this.questionGroup, this.currentIndex, question);
		},
		computedOptions(str) {
			str = str || '';
			str = str.replace(/<ol>/g, '');
			str = str.replace(/<ul>/g, '');
			str = str.replace(/<\/ul>/g, '');
			str = str.replace(/<\/ol>/g, '');
			str = str.replace(/<li>/g, '');
			str = str.replace(/<\/li>/g, '');
			let arr = str.split('\n');
			let targetArr = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i]) {
					targetArr.push({
						label: this.formatAddPointWords(this.formatMathJaxImg(arr[i])),
						status: 0
					});
				}
			}
			return targetArr;
		},
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: 2,
					from: 'user'
				}
			});
		}
	}
};
</script>
<style lang="scss">
.question-content {
	img {
		vertical-align: middle;
	}
	em {
		position: relative;
		font-style: normal;
		&::after {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 100%);
			content: '';
			width: 0.08rem;
			height: 0.08rem;
			background: #333;
			border-radius: 50%;
		}
	}
}
.question-body {
	* {
		sub,
		sup {
			font-size: 0.5em !important;
		}
	}
	.stem {
		line-height: 1.6 !important;
		font-size: 0.3rem !important;
		font-family: initial !important;
	}
}
.question-area {
	.content-area {
		line-height: 1.6 !important;
		font-size: 0.3rem !important;
		font-family: initial !important;
	}
}
.question-body {
	line-height: 1.6;
	.stem {
		margin-bottom: 0.3rem;
	}
	.options {
		li {
			position: relative;
			padding: 0.3rem 0.3rem 0.3rem 40px;
			margin: 0.3rem 0;
			cursor: default;
			background: #f8f8f8;
			border-radius: 0.1rem;
			font-size: 0.28rem;
			&::after {
				position: absolute;
				left: 0.3rem;
				top: 50%;
				transform: translateY(-50%);
				padding-right: 0.1rem;
			}
			&::before {
				position: absolute;
				left: 0.6rem;
				top: 50%;
				transform: translateY(-50%);
				padding-right: 0.1rem;
				content: '';
				height: 0.2rem;
				border-left: 1px #ccc solid;
			}
			&:nth-child(1)::after {
				content: 'A';
			}
			&:nth-child(2)::after {
				content: 'B';
			}
			&:nth-child(3)::after {
				content: 'C';
			}
			&:nth-child(4)::after {
				content: 'D';
			}
			&:nth-child(5)::after {
				content: 'E';
			}
			&:nth-child(6)::after {
				content: 'F';
			}
			&:nth-child(7)::after {
				content: 'G';
			}
			&:nth-child(8)::after {
				content: 'H';
			}
			&:nth-child(9)::after {
				content: 'I';
			}
			&:nth-child(10)::after {
				content: 'J';
			}
		}
		.active {
			background: #e2f5f1;
		}
		.proper {
			background: #1e9e85;
			color: #fff;
		}
		.error {
			color: #fff;
			background: #f00;
		}
	}
}
.content-area {
	.con-box {
		font-size: 0.26rem;
		line-height: 1.6;
	}
}
.fill-blanks {
	display: flex;
	margin-bottom: 0.3rem;
}
</style>
<style lang="scss" scoped>
.question-body {
	transition: all 0 0.3s ease-in-out;
}
.question-body.show {
	opacity: 1;
}
.question-body.hide {
	opacity: 0;
}
.user-textarea {
	margin-top: 0.3rem;
	width: 100%;
	padding: 0.3rem;
	border: 1px #dcdcdc solid;
	border-radius: 0.1rem;
	resize: none;
}
.result-list {
	li {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.3rem;
		text-align: justify;
		.label-for {
			font-size: 0.26rem;
			line-height: 1.75em;
			color: #1e9e85;
			word-break: keep-all;
			margin-right: 0.3rem;
		}
		.label-text {
			font-size: 0.26rem;
			line-height: 1.75em;
		}
	}
}
.question-area {
	transition: all 0 0.3s ease-in-out;
	.area {
		width: 100%;
		margin-bottom: 0.3rem;
	}
	.main-area {
		overflow: auto;
	}
	.flexBox {
		display: flex;
		border-right: 0 !important;
		.box {
			height: 100%;
			&:first-child {
				border-right: 1px #dcdcdc dashed;
			}
		}
	}
}

.acount-question {
	position: fixed;
	right: 0.3rem;
	top: 0.24rem;
	height: 1rem;
	z-index: 1000;
	font-size: 0.32rem;
	color: #969799;
	span {
		font-size: 0.4rem;
		color: #1e9e85;
	}
}
.course-content {
	width: 100vw;
	height: 100vh;
	overflow: auto;
	padding: 1.4rem 0.3rem 1rem 0.3rem;
	background: #fff;
}
</style>

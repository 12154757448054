const globalMethods = {
  methods: {
    addHistory() {
      let query = this.$route.query;
      this.$api
        .addHistory({
          course_id: query.bookId,
          subject_id: query.childId || query.channelId || query.classId,
          learningrecord: {
            path: this.$route.path,
            query: { ...query },
            curseimg: this.$store.getters["common/getClassMask"],
            menutype:window.sessionStorage.getItem('menutype')
          },
        })
        .then(() => {
          //console.log(res);
        });
    },
    gobackChapter() {
      let query = this.$route.query;
      delete query.unitId;
      delete query.unitName;
      delete query.classId;
      delete query.className;
      delete query.channelId;
      delete query.channelName;
      delete query.childId;
      delete query.childName;

      window.sessionStorage.setItem("innerClassId", "");
      window.sessionStorage.setItem("innerClassName", "");
      window.sessionStorage.setItem("innerChannelId", "");
      window.sessionStorage.setItem("innerChannelName", "");
      if (query.isHistory==1) {
        this.$router.replace({
          path: '/',
          query: {
            active:0
          },
        });
      } if (query.isHistory==2) {
        this.$router.replace({
          path: '/moreHistory',
          query: {
            active:2,
            from:'user'
          },
        });
      }else {
        this.$router.push({
          path: `/${this.$route.query.from}`,
          query: {
            ...query,
            from: "zhiShiQuanJie",
            // from: "channel",
          },
        });
      }
    },

    gobackMoudle() {
      let query = this.$route.query;
      delete query.unitId;
      delete query.unitName;
      delete query.classId;
      delete query.className;
      delete query.channelId;
      delete query.channelName;
      delete query.childId;
      delete query.childName;

      window.sessionStorage.setItem("innerClassId", "");
      window.sessionStorage.setItem("innerClassName", "");
      window.sessionStorage.setItem("innerChannelId", "");
      window.sessionStorage.setItem("innerChannelName", "");
      this.$router.push({
        path: `/${this.$route.query.from || '#/'}`,
        query: {
          ...query,
          from: "channel",
        },
      });
    },
  },
};

export default globalMethods;

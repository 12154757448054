<template>
<div class="error-page">
<span class="not-fond">此页面不存在</span>
<div class="back-btn" @click="backIndex">返回首页</div>
</div>
</template>

<script>
export default {
name:"errorPage",
methods:{
backIndex(){
this.$router.replace({
path:'/'
})
}
}
}
</script>

<style lang="scss" scoped>
.error-page{
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
font-size: .36rem;
color: #999;
.not-fond{
padding-bottom: 1rem;
}
.back-btn{
padding: .1rem .3rem;
background: #00C18C;
color: #fff;
font-size: .28rem;
border-radius: .1rem;
}
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "index",
    component: () => import("@/views/mobileIndex/index.vue"),
  },{
    path: "/specialSubject",
    name: "specialSubject",
    component: () => import("@/views/mobileIndex/specialSubject.vue"),
  },{
    path: "/lecturenotes",
    name: "lecturenotes",
    component: () => import("@/views/mobileIndex/lecturenotes.vue"),
  },{
    path: "/examinationpaper",
    name: "examinationpaper",
    component: () => import("@/views/mobileIndex/examinationpaper.vue"),
  },
  {
    path: "/zhenTi",
    name: "zhenTi",
    component: () => import("@/views/mobileIndex/zhenTi.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user/index.vue"),
  },
  {
    path: "/errorBook",
    name: "errorBook",
    component: () => import("@/views/user/errorBook.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/user/history.vue"),
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("@/views/user/changePassword.vue"),
  },
  {
    path: "/errorQuestion",
    name: "errorQuestion",
    component: () => import("@/views/user/errorQuestion.vue"),
  },
  {
    path: "/version",
    name: "version",
    component: () => import("@/views/user/version.vue"),
  },
  {
    path: "/editUser",
    name: "editUser",
    component: () => import("@/views/user/editUser.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/sigin",
    name: "sigin",
    component: () => import("@/views/sigin.vue"),
  },
  {
    path: "/complateInfs",
    name: "complateInfs",
    component: () => import("@/views/complateInfs.vue"),
  },
  {
    path: "/webView",
    name: "webView",
    component: () => import("@/views/webView.vue"),
  },
  {
    path: "*",
    name: "errorPage",
    component: () => import("@/views/errorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    history.pushState(null, null, document.URL);
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});

//判断是否登录
router.beforeEach(function (to, from, next) {
  let token = window.localStorage.getItem("token");
  if (to.path != "/login" && to.path != "/sigin") {
    //通过查看token判断是否登录
    if (token && token != "") {
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login",
      });
    }

    if (to.path != "/complateInfs") {
      let isComplated = window.localStorage.getItem("complatedStatus");
      if (isComplated != 0) {
        next(); //表示已经完成完善信息
      } else {
        //next可以传递一个路由对象作为参数 表示需要跳转到的页面
        next({
          name: "complateInfs",
        });
      }
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

export default router;

<template>
	<div class="pageBox">
		<div class="title">{{title}}</div>
		<div class="content">
			<menuItem  :menuData="menuData" :isTypeExamin='isTypeExamin'></menuItem>
		</div>
		<div class="bnt" @click="showMenu">{{bntText}}</div>
	</div>
</template>

<script>
export default {
	name: 'popupMenu',
	components: {
		menuItem: () => import('@/components/treeMenu/menuItem.vue'),
	},
	props: {
		title: {
			type: [Number, String],
			default: ''
		},bntText: {
			type: [Number, String],
			default: '查看视频'
		},
		isTypeExamin: {
			type: [Boolean, Number],
			default:true 
		},
		menuData: {
			type: [Object, Array],
			default: () => {
				return [];
			}
		},
	},
	watch: {		
	},
	data() {
		return {
			menuActive:[]
		};
	},
	async created() {
	},
	mounted() {
	},
	methods: {
		
		showMenu(){
			if(this.menuData.length==0){
				return false;
			}
			this.$eventBus.$emit('getShowMenu');
		},
	
		goMoudle() {
			this.$router.push({
				query: {
					from: ''
				},
				path: '/specialSubject'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
	.pageBox{
		width: 100%;
		height: 100%;
		padding: 0 0rem;
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		.title{
			height: 1rem;
			line-height: 1rem;
			font-size: 0.32rem;
			padding: 0 0.24rem;
		}
		.content{
			flex: 1;
			overflow: auto;
		}
		.bnt{
			height: 0.8rem;
			font-size: 0.32rem;
			width: calc(100% - 0.48rem);
			background-color: #05549A;
			border-radius: 0.4rem;
			
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0.24rem ;
			color: #fff;
		}
	}
	
</style>

<template>
	<div class="page-box hasnav notab">
		<navBar :showBack="false">
			<span class="title">完善个人信息</span>
		</navBar>
		<div class="infs-from-box">
			<div class="infs-from">
				<div class="infs-item" v-for="(item, tag) in formData" :key="tag">
					<div class="item-label">
						<span class="require" v-if="item.require">*</span>
						<span class="label">{{ item.label }}：</span>
					</div>
					<div class="item-input" v-if="item.type == 0">
						<input
							v-model="item.data"
							:placeholder="item.placeholder"
							:maxlength="item.maxlength"
							:type="item.inputType || 'text'"
						/>
					</div>
					<div class="item-input" v-if="item.type == 1">
						<span @click="item.data = 1" class="gender" :class="{ 'gender-select-boy': item.data == 1 }">
							<i class="iconfont icon-xingbienan"></i>
							男生
						</span>
						<span @click="item.data = 2" class="gender" :class="{ 'gender-select-girl': item.data == 2 }">
							<i class="iconfont icon-xingbienv"></i>
							女生
						</span>
					</div>
					<div class="item-input" v-if="item.type == 2">
						<input
							type="text"
							v-model="item.data"
							:placeholder="item.placeholder"
							:maxlength="item.maxlength"
						/>
						<van-icon name="arrow" class="arrow" />
						<div class="mask" @click="pickerAction(tag)"></div>
					</div>
					<div class="item-input" v-if="item.type == 3">
						<input
							type="text"
							v-model="item.data"
							:placeholder="item.placeholder"
							:maxlength="item.maxlength"
						/>
						<div class="send-code" :class="{ disable: isSend }" @click="sendCodeAction">
							{{ isSend ? sendText : '发送验证码' }}
						</div>
					</div>
				</div>
			</div>
			<div class="enter-learn" @click="submitAction">进入学习</div>
		</div>
		<div class="area-picker" v-if="showPicker" @click="showPicker = false">
			<div class="picker-main">
				<div class="picker-header">
					<div class="btn cancle" @click="showPicker = false">取消</div>
					<div class="tit">{{ computeTitle() }}</div>
					<div class="btn confirm" @click="confirmAction(currentTag)">确认</div>
				</div>
				<div class="picker-body">
					<ul class="picker-data" v-if="pickerData.length">
						<li
							v-for="(item, index) in pickerData"
							:class="{ active: selectData.label == item.label }"
							:key="index"
							@click.stop="selectPicker(index, item)"
						>
							{{ item.label }}
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="invite-layer" v-if="layerStatus">
			<div class="invite-main">
				<span class="invite-status">正在激活</span>
				<div class="invite-item">
					<van-field
						v-model="inviteCode"
						maxlength="8"
						center
						clearable
						class="invite-code"
						placeholder="请输入激活码"
					></van-field>
				</div>
				<div class="invite-confirm" @click="inviteAction">确定激活</div>
			</div>
		</div>
	</div>
</template>

<script>
import adminRegions from '@/utils/city.json';
let timer = null;
export default {
	name: 'complateInfs',
	data() {
		return {
			layerStatus: false,
			isSend: false,
			isSubmiting: false,
			sendText: '60s后重新发送',
			showPicker: false,
			formData: {
				name: {
					label: '姓名',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入学生姓名',
					maxlength: 6
				},
				gender: {
					label: '性别',
					require: true,
					data: 1,
					type: 1,
					maxlength: 2
				},
				nickname: {
					label: '昵称',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入学生昵称',
					maxlength: 10
				},
				province: {
					label: '省份',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在省份'
				},
				city: {
					label: '城市',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在城市'
				},
				area: {
					label: '所在区(县)',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在区(县)'
				},
				school: {
					label: '学校名称',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入学校名称',
					maxlength: 15
				},
				grade: {
					label: '所在年级',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在年级'
				},
				mobile: {
					label: '家长电话号码',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入家长电话号码',
					maxlength: 11,
					inputType: 'number'
				},
				code: {
					label: '验证码',
					require: true,
					data: '',
					type: 3,
					placeholder: '请输入手机验证码',
					maxlength: 6,
					inputType: 'number'
				}
			},
			currentTag: '',
			selectData: {},
			pickerData: [],
			cityData: [],
			areaData: [],
			gradeData: [],

			inviteCode: ''
		};
	},
	created() {
		this.layerStatus = window.localStorage.getItem('layerStatus') == 1 ? false : true;
		this.$api.versionGrade().then((res) => {
			let resData = res.data || [];
			let gradeData = [];
			for (let i = 0; i < resData.length; i++) {
				gradeData.push({
					label: resData[i],
					value: i
				});
			}
			this.gradeData = gradeData;
		});
	},
	mounted() {},
	methods: {
		inviteAction() {
			if (!this.inviteCode) {
				this.$notify({
					type: 'warning',
					message: '请输入激活码'
				});
				return false;
			}
			this.$api
				.userActivation({
					code: this.inviteCode
				})
				.then((res) => {
					let messageType = 'success';
					if (res.code == 0) {
						this.layerStatus = false;
						window.localStorage.setItem('layerStatus', 1);
						messageType = 'success';
						let userInfs = this.$store.getters['userCenter/getUserInfs'];
						userInfs.select_subject = 0;
						userInfs.maxsubjectnum = res.data.maxsubjectnum;
						this.$store.commit('userCenter/setUserInfs', userInfs);
					} else {
						messageType = 'warning';
					}
					this.$notify({
						type: messageType,
						message: res.message
					});
				});
		},
		submitAction() {
			if (this.isSubmiting) {
				return false;
			}
			let params = {};
			let hanndle = true;
			for (let i in this.formData) {
				if (this.formData[i].require && !this.formData[i].data) {
					hanndle = false;
					this.$notify({
						type: 'warning',
						message: `请${this.formData[i].type == 0 || this.formData[i].type == 3 ? '输入' : '选择'}${
							this.formData[i].label
						}`
					});
					break;
				} else {
					if (i == 'mobile' && !this.regTel(this.formData.mobile.data)) {
						hanndle = false;
						break;
					}
					params[i] = this.formData[i].data;
				}
			}
			if (hanndle) {
				this.$toast({
					message: '保存中...',
					type: 'loading'
				});
				this.$api
					.checkPhone({
						register_mobile: this.formData.mobile.data,
						register_mobile_code: this.formData.code.data
					})
					.then((res) => {
						if (!['4000', '4001', '4002'].includes(res.code)) {
							this.isSubmiting = true;
							let params = {
								name: this.formData.name.data,
								nick_name: this.formData.nickname.data,
								gender: this.formData.gender.data,
								school: this.formData.school.data,
								grade: this.formData.grade.data,
								bind_mobile: this.formData.mobile.data,
								register_area:
									this.formData.province.data +
									'/' +
									this.formData.city.data +
									'/' +
									this.formData.area.data
							};
							this.$api.uploadUserInfos(params).then((res) => {
								this.isSubmiting = false;
								this.$notify({
									type: 'success',
									message: res.message
								});
								if (res.code != 500) {
									window.localStorage.setItem('complatedStatus', 1);
									this.$router.replace({
										path: '/',
										query: {
											active: 0
										}
									});
								}
							});
						} else {
							this.$notify({
								type: 'warning',
								message: res.msg
							});
						}
					});
			}
		},
		sendCodeAction() {
			if (this.regTel(this.formData.mobile.data)) {
				if (!this.isSend) {
					this.sendCodeFun();
				}
			}
		},
		sendCodeFun() {
			this.$api
				.sendSms({
					register_mobile: this.formData.mobile.data,
					scene: 'mobile_bind'
				})
				.then((res) => {
					this.$notify({
						type: 'success',
						message: res.message
					});
					this.isSend = true;
					this.sendText = `60s后重新发送`;
					clearInterval(timer);
					this.computeTime();
				});
		},

		regTel(str) {
			let reg = /^1[3-9]\d{9}$/;
			if (!reg.test(str)) {
				this.$notify({
					type: 'warning',
					message: '请输入正确格式的手机号码！'
				});
				return false;
			} else {
				return true;
			}
		},
		computeTime() {
			let num = 60;
			timer = setInterval(() => {
				if (1 < num) {
					this.sendText = `${--num}s后重新发送`;
				} else {
					clearInterval(timer);
					this.isSend = false;
				}
			}, 1000);
		},
		confirmAction(tag) {
			this.$set(this.formData[tag], 'data', this.selectData.label);
			if (this.currentTag == 'province') {
				this.$set(this.formData.city, 'data', '');
				this.$set(this.formData.area, 'data', '');
			}
			if (this.currentTag == 'city') {
				this.$set(this.formData.area, 'data', '');
			}
		},
		selectPicker(index, item) {
			if (this.currentTag == 'province') {
				this.cityData = adminRegions[index].children;
			}
			if (this.currentTag == 'city') {
				this.areaData = this.cityData[index].children;
			}
			this.selectData = item;
		},
		computeTitle() {
			if (this.currentTag == 'province') {
				return '请选择省份';
			}
			if (this.currentTag == 'city') {
				return '请选择城市';
			}
			if (this.currentTag == 'area') {
				return '请选择区(县)';
			}
		},
		pickerAction(tag) {
			if (tag == 'province') {
				this.pickerData = adminRegions;
			}
			if (tag == 'city') {
				if (!this.formData.province.data) {
					this.$notify({
						type: 'warning',
						message: '请先选择省份！'
					});
					return false;
				}
				this.pickerData = this.cityData;
			}
			if (tag == 'area') {
				if (!this.formData.province.data) {
					this.$notify({
						type: 'warning',
						message: '请先选择省份！'
					});
					return false;
				}
				if (!this.formData.city.data) {
					this.$notify({
						type: 'warning',
						message: '请先选择城市！'
					});
					return false;
				}
				this.pickerData = this.areaData;
			}
			if (tag == 'grade') {
				this.pickerData = this.gradeData;
			}
			this.currentTag = tag;
			this.showPicker = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.page-box.hasnav {
	top: 0rem;
}
.infs-from-box {
	margin-top: 1.08rem;
	height: calc(100% - 1.28rem);
	overflow: auto;
	border-top: 0.2rem solid #f5f5f5;
}
.invite-layer {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	background: rgba(0, 0, 0, 0.8);

	.invite-main {
		display: flex;
		flex-direction: column;
		width: 80%;
		padding: 0.5rem;
		background: #fff;
		font-size: 0.24rem;
		text-align: center;

		.invite-item {
			margin: 0.5rem 0;
			border: 1px #dcdcdc solid;

			::v-deep .van-field__control {
				text-align: center;
			}
		}

		.invite-confirm {
			background: #05549a;
			padding: 0.26rem 0.5rem;
			color: #fff;

			&:active {
				opacity: 0.8;
			}
		}
	}
}

.area-picker {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	display: flex;
	align-items: flex-end;
	background: rgba(0, 0, 0, 0.3);

	.picker-main {
		width: 100%;
		height: 50vh;
		background: #fff;
		border-radius: 20px 20px 0 0;
		overflow: hidden;
		animation: showPicker 0.2s ease-in-out;

		@keyframes showPicker {
			0% {
				opacity: 0.5;
				transform: translateY(50%);
			}

			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}

		.picker-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 15px;
			height: 50px;
			font-size: 0.28rem;
			color: #333;
			font-weight: 400;
			box-shadow: 0 0 5px #ccc;

			.cancle {
				color: #ccc;
			}

			.confirm {
				color: #05549a;
			}
		}

		.picker-body {
			width: 100%;
			height: calc(50vh - 50px);
			overflow-y: auto;
		}

		.picker-data {
			width: 100%;
			min-height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			li {
				width: 100%;
				padding: 0 20px;
				height: 40px;
				font-size: 0.24rem;
				color: #333;
				line-height: 40px;
				font-weight: 400;
				border-bottom: 1px #f1f1f1 solid;

				&:last-child {
					border: 0;
				}
			}

			.active {
				background: #05549a;
				color: #fff;
			}
		}
	}
}

.enter-learn {
	margin: 0.7rem 0.3rem;
	height: 49px;
	background: #05549a;
	border-radius: 49px;
	text-align: center;
	line-height: 49px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;

	&:active {
		opacity: 0.8;
	}
}

.page-box {
	.infs-from {
		padding: 0 0.3rem;
	}

	.infs-item {
		display: flex;
		align-items: center;
		height: 1rem;
		border-bottom: 1px #dcdcdc solid;

		.item-label {
			display: flex;
			align-items: center;
			font-size: 0.28rem;
			color: #333;
			font-weight: 400;
			word-break: break-all;
			min-width: 125px;

			.require {
				color: #ff3838;
				padding-right: 9px;
			}
		}

		.item-input {
			position: relative;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 100%;
			overflow: hidden;

			.mask {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;
			}

			input {
				margin: 0;
				border: 0;
				width: 100%;
				height: 50px;
				font-size: 0.28rem;
			}

			.arrow {
				position: relative;
				z-index: 2;
				font-size: 0.28rem;
				color: #909090;
			}

			.gender {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 60px;
				height: 24px;
				background: #ededed;
				border-radius: 0.24rem;
				font-size: 0.24rem;
				color: #909090;
				font-weight: 400;
				margin-left: 6px;

				&-select {
					&-boy {
						color: #fff;
						background: #4892ff;
					}

					&-girl {
						color: #fff;
						background: #ff554c;
					}
				}
			}

			.send-code {
				position: absolute;
				right: 0;
				top: 50%;
				z-index: 2;
				width: 100px;
				height: 28px;
				background: #05549a;
				border-radius: 0.28rem;
				transform: translateY(-50%);
				font-size: 0.26rem;
				color: #fff;
				text-align: center;
				line-height: 28px;

				&:active {
					opacity: 0.8;
				}
			}

			.disable {
				background: #dcdcdc;
			}
		}
	}
}
</style>

<template>
	<ul class="course-list" v-if="courseData.length">
		<template v-for="(item, index) in courseData">
			<li
				v-if="item.subject_id == currentSubject || (currentSubject == '100' && index < limitNum)"
				:key="index"
				@click="goDetail(item)"
			>
				<div class="course-img">
					<van-image
						class="img"
						fit="cover"
						:src="setCurseImg(item.learningrecord.query.subjectName)"
					></van-image>
				</div>
				<div class="course-infs">
					<div class="course-name">
						{{ item.updated_at.split('T')[0] }}
						{{ item.learningrecord.query.subjectName }}
					</div>
					<div class="course-author-unit">
						<span v-if="item.learningrecord.query.moudleName && item.learningrecord.query.moudleName != ''">
							{{ item.learningrecord.query.moudleName }}
						</span>
						<span v-if="item.learningrecord.query.bookName && item.learningrecord.query.bookName != ''">
							| {{ item.learningrecord.query.bookName }}
						</span>
						<span v-if="item.learningrecord.query.unitName && item.learningrecord.query.unitName != ''">
							| {{ item.learningrecord.query.unitName }}
						</span>
						<span v-if="item.learningrecord.query.className && item.learningrecord.query.className != ''">
							| {{ item.learningrecord.query.className }}
						</span>
						<span
							v-if="item.learningrecord.query.channelName && item.learningrecord.query.channelName != ''"
						>
							| {{ item.learningrecord.query.channelName }}
						</span>
						<span v-if="item.learningrecord.query.childName && item.learningrecord.query.childName != ''">
							| {{ item.learningrecord.query.childName }}
						</span>
					</div>
				</div>
			</li>
		</template>
	</ul>
</template>

<script>
import { setSourceUrl, removeSpace } from '@/utils/public';
export default {
	name: 'courseList',
	props: {
		courseData: {
			type: Array,
			default: () => {
				return [];
			}
		},
		currentSubject: {
			type: [String, Number],
			default: '100'
		},
		limitNum: {
			type: [String, Number],
			default: '100'
		}
	},
	data() {
		return {
			yuwen: require('@/assets/image/historyIcon/语文.png'),
			shuxue: require('@/assets/image/historyIcon/数学.png'),
			yingyu: require('@/assets/image/historyIcon/英语.png'),
			wuli: require('@/assets/image/historyIcon/物理.png'),
			huaxue: require('@/assets/image/historyIcon/化学.png'),
			zhengzhi: require('@/assets/image/historyIcon/政治.png'),
			lishi: require('@/assets/image/historyIcon/历史.png'),
			shengwu: require('@/assets/image/historyIcon/生物.png'),
			dili: require('@/assets/image/historyIcon/地理.png'),
			kexue: require('@/assets/image/historyIcon/科学.png')
		};
	},
	created() {
		//console.log(this.courseData);
	},
	methods: {
		setSourceUrl,
		removeSpace,
		setCurseImg(str) {
			let imgSrc = '';
			switch (str) {
				case '语文': {
					imgSrc = this.yuwen;
					break;
				}
				case '数学': {
					imgSrc = this.shuxue;
					break;
				}
				case '英语': {
					imgSrc = this.yingyu;
					break;
				}
				case '物理': {
					imgSrc = this.wuli;
					break;
				}
				case '化学': {
					imgSrc = this.huaxue;
					break;
				}
				case '政治': {
					imgSrc = this.zhengzhi;
					break;
				}
				case '历史': {
					imgSrc = this.lishi;
					break;
				}
				case '生物': {
					imgSrc = this.shengwu;
					break;
				}
				case '地理': {
					imgSrc = this.dili;
					break;
				}
				default: {
					imgSrc = this.kexue;
				}
			}
			return imgSrc;
		},
		goDetail(item) {
			let query = item.learningrecord.query;
			query.from = '';
			this.$router.replace({
				path: item.learningrecord.path,
				query: query
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.course-list {
	li {
		width: 100%;
		margin-bottom: 0.3rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.course-img {
			width: .9rem;
			margin-right: .26rem;
			overflow: hidden;
			.img {
				width: 100%;
				
			}
		}
		.course-infs {
			flex: 1;
			display: flex;
			justify-content: center;
			flex-direction: column;
			.course-name {
				width: 100%;
				font-size: 0.28rem;
				font-weight: 500;
				color: #333;
				margin-bottom: 0.16rem;
			}
			.course-author-unit {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 0.3rem;
				font-weight: 400;
				color: #909090;
				span {
					font-size: 0.24rem;
				}
			}
			.course-time-status {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				div {
					display: flex;
					color: #909090;
					font-weight: 400;
					font-size: 0.24rem;
					.iconfont {
						margin-right: 0.16rem;
					}
				}
				.time {
					margin-right: 0.4rem;
				}
			}
		}
	}
}
</style>

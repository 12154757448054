<template>
<div class="empty-box">
<img :src="emptyImg" class="empty-img">
</div>
</template>

<script>
export default {
name:"emptyBox",
data(){
return {
 emptyImg:require('@/assets/image/empty.png')
}
}
}
</script>

<style lang="scss" scoped>
.empty-box{
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background: #fff;
.empty-img{
width: 4.5rem;
height: auto;
margin-bottom: 1rem;
}
}
</style>
<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">修改密码</span>
		</navBar>
		<div class="page-box notab hasnav">
			<div class="page-content" style="padding-top: 1.18rem">
				<van-field
					v-model="oldPassword"
					type="password"
					label="旧密码"
					placeholder="请填写旧密码"
					:clearable="true"
				/>
				<van-field
					v-model="newPassword"
					type="password"
					label="新密码"
					placeholder="请输入新的密码"
					:clearable="true"
				/>
				<van-field
					v-model="confirmPassword"
					type="password"
					label="确认新密码"
					placeholder="请再次输入新的密码"
					:clearable="true"
				/>
				<!-- <p class="notic">密码是8-16位英文字母、数字、字符组合（不能是纯数字）</p> -->
				<p class="notic">密码是6位纯数字</p>
				<div class="submit-btn" @click="onSubmit">提交</div>
			</div>
		</div>
	</div>
</template>

<script>
import { removeToken } from '@/utils/token';
export default {
	name: 'changePasswordPage',
	data() {
		return {
			changeType: 0,
			oldPassword: '',
			newPassword: '',
			confirmPassword: ''
		};
	},
	created() {
		this.changeType = this.$route.query.type;
	},
	mounted() {},
	methods: {
		removeToken,
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: 2
				}
			});
		},
		onSubmit() {
			if (!this.oldPassword) {
				this.$notify({ type: 'warning', message: '请输入旧密码！' });
				return;
			}
			if (!this.newPassword) {
				this.$notify({ type: 'warning', message: '请输入新密码！' });
				return;
			}
			// let reg =
			// /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{8,16}$/;
			let reg = /^\d{6}$/;
			if (!reg.test(this.newPassword)) {
				this.$notify({ type: 'warning', message: '密码是6位纯数字' });
				return;
			}
			if (!this.confirmPassword) {
				this.$notify({ type: 'warning', message: '请输入确认新密码！' });
				return;
			}
			if (this.newPassword != this.confirmPassword) {
				this.$notify({
					type: 'warning',
					message: '新密码与确认新密码不一致！'
				});
				return;
			}
			this.$toast({
				message: '保存中...',
				type: 'loading'
			});
			this.$api
				.changePassword({
					password: this.oldPassword,
					newpassword: this.newPassword
				})
				.then((res) => {
					if (res.code == 0) {
						this.$notify({ type: 'success', message: res.message });
						this.removeToken();
						setTimeout(() => {
							window.localStorage.clear();
							window.location.reload();
						}, 1000);
					} else {
						this.$notify({ type: 'warning', message: res.message });
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.submit-btn {
	margin: 2rem 0.28rem 0 0.28rem;
	text-align: center;
	line-height: 0.88rem;
	height: 0.88rem;
	background: #05549a;
	box-shadow: 0px 0.06rem 0.14rem 0px rgba(45, 183, 154, 0.35);
	border-radius: 0.88rem;
	font-size: 0.3rem;
	font-weight: 500;
	color: #fff;
	&:active {
		background: #90d9ca;
	}
}
.notic {
	padding-top: 0.4rem;
	text-align: center;
	font-weight: 400;
	color: #909090;
	font-size: 0.28rem;
}
</style>

<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">错题本</span>
		</navBar>
		<div class="page-box hasnav notab haspadding">
			<loading-box v-if="isLoading"></loading-box>
			<ul class="error-list" v-else>
				<li v-for="(item, tag) in dataList" :key="tag" @click="goNext(item)">
					<div class="infs">
						<van-image class="icon" fit="cover" :src="item.icon"></van-image>
						<div class="course-num">
							<div class="course">{{ item.name }}</div>
							<div class="num">错题数：{{ item.count ? item.count : 0 }}</div>
						</div>
					</div>
					<span class="time">{{ item.time }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'errorBook',
	data() {
		return {
			isLoading: false,
			icons: {
				// 语文: require('@/assets/img/语文.png'),
				// 数学: require('@/assets/img/数学.png'),
				// 英语: require('@/assets/img/英语.png'),
				// 物理: require('@/assets/img/物理.png'),
				// 化学: require('@/assets/img/化学.png'),
				// 政治: require('@/assets/img/政治.png'),
				// 地理: require('@/assets/img/地理.png'),
				// 历史: require('@/assets/img/历史.png'),
				// 科学: require('@/assets/img/科学.png'),
				// 生物: require('@/assets/img/生物.png')
			},
			dataList: []
		};
	},
	created() {
		this.initData();
	},
	mounted() {},
	methods: {
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: this.$route.query.active
				}
			});
		},
		goNext(item) {
			if (item.count) {
				this.$router.push({
					path: '/errorQuestion',
					query: {
						subjectName: item.name,
						errorId: item.id,
						from: 'errorbook'
					}
				});
			} else {
				this.$notify({
					type: 'warning',
					message: '暂无数据！'
				});
			}
		},
		initData() {
			this.isLoading = true;
			this.$api.baseErrorQuestion().then((res) => {
				let resData = res.data || {};
				Object.keys(resData).forEach((item) => {
					resData[item].icon = this.icons[resData[item].name];
				});
				this.dataList = resData;
				this.isLoading = false;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.error-list {
	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.28rem 0;
		border-bottom: 1px #dcdcdc solid;
		font-size: 0.28rem;
		&:active {
			background: rgba(0, 0, 0, 0.01);
		}
		&:last-child {
			border: 0;
		}
		.time {
			color: #909090;
			font-weight: 400;
		}
		.infs {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.icon {
				width: 0.96rem;
				height: 1.04rem;
				margin-right: 0.26rem;
			}
			.course {
				font-weight: 500;
				color: #333;
				font-size: 0.28rem;
			}
			.num {
				font-weight: 400;
				color: #909090;
			}
		}
	}
}
</style>

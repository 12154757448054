<template>
	<ul class="menu-item" :class="`nav-type-${navType}`">
		<template v-for="(menu, index) in menuList">
			<li :key="`${index}-${menu.index}`" :class="{ noborder: menu.index == 0 }" v-if="menu.title != '动画课件'">
				<div class="item-box" :class="{active:menu.active&&(menuLayer==2||menuLayer==0)}">
					<div
						class="item-con"
						:class="{
							hasChild: menu.type == 2 && menu.index != 0,
							showChild: menu.showChild && menu.index != 0,
							garybg: menu.index == 0&&isTypeExamin,
							hasIcon: html2string(menu.title) == '名师考点精讲'
						}"
						@click="selectMenu(menu, index)"
					>
						<div class="con">
							<van-image
								class="icon"
								fit="cover"
								:src="setSourceUrl(menu.h5icon || menu.image)"
								:class="`icon-${menu.index}`"
								v-if="menu.h5icon && menu.index != 0&&menu.pid !=141"
							></van-image>
							<span
								class="title"
								:class="{ strongText: menu.index == 0 }"
								v-if="html2string(menu.name)"
							>{{menu.name}}</span>
							<span v-else style="padding-right: 0.3rem">
								<van-image
									class="mingshijingjiang"
									fit="cover"
									:src="setSourceUrl(menu.navimage)"
								></van-image>
							</span>
						</div>
						<!-- <van-icon class="arrow" name="arrow" style="margin-right: 0.3rem" v-if="menu.index != 0" /> -->
					</div>
					<menuItem
						:menuLayer="layerNum"
						:fromPage="fromPage"
						:menuData="menu.children"
						:moudleIndex="index"
						:parentName="menu.title"
						:class="{ hideChild: !menu.showChild }"
						v-if="menu.children && menuLayer<2"
					></menuItem>
				</div>
			</li>
		</template>
	</ul>
</template>

<script>
import { setSourceUrl, setQuestion, removeSpace, html2string } from '@/utils/public.js';
export default {
	name: 'menuItem',
	props: {
		menuData: {
			type: [Object, Array],
			default: () => {
				return [];
			}
		},
		navType: {
			type: [String, Number],
			default: 0
		},
		isTypeExamin: {
			type: [Boolean, Number],
			default:true 
		},
		fromPage: {
			type: [Number, String],
			default: ''
		},
		menuLayer: {
			type: [String, Number],
			default: 0
		}
	},
	watch: {
		menuData: {
			handler: function (val) {
				console.log(val)
				this.menuList = val;
			},
			immediate: true
		}
	},
	data() {
		return {
			currentIdx: 0,
			menuList: [],
			subjectName: this.$route.query.subjectName,
			childrenMenu: [],
			innerUnitId: '',
			innerUnitName: '',
			openIds: [],
			openid: '',
			layerNum:0
		};
	},
	created() {
		let _menuLayer = this.menuLayer;
		this.moudleName = this.$route.query.moudleName;
		this.layerNum = ++_menuLayer;
		
	},
	
	mounted() {},
	methods: {
		setSourceUrl,
		html2string,
		removeSpace,

		selectMenu(item, index, hanndle = true) {
			// if (item.index == 0) {
			// 	return false;
			// }
			// this.forEachData(this.menuList, item.id);
			console.log(this.menuLayer)
			// if (item.menutype_id == 1) {
				// if (item.children && !this.subsetProhibitFn(item.name)) {
					
					this.forEachData(this.menuList, item.id);
				// } else {
					
					this.goPage(item);
				// }
			
		},
		subsetProhibitFn(name) {
			let nameData = ['名家朗读', '课文朗读', '', ''];
			return nameData.includes(name);
		},
		
		forEachData(arr, id) {
			if (Array.isArray(arr)) {
				arr.forEach((item) => {
					if (item.id == id) {
						item.showChild = !item.showChild;
					} else {
						item.showChild = false;
						item.active = false;
					}
					if (item.children) {
						this.forEachData(item.children, id);
					}
				});
			}
			this.$forceUpdate();
		},
		goPage(item) {
			item.menuLayer = this.menuLayer
			this.$eventBus.$emit('menuActive', item);
			item.active = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.mingshijingjiang {
	width: 100%;
	padding: 0.1rem 0;
}
.menu-item {
	height: initial;
	font-size: 0.28rem;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	background: #fff;
	color: #232323;
	li {
		border-bottom: 1px #dcdcdc solid;

		&:last-child {
			border: 0;
		}
	}
	// .noborder {
	// 	border: 0;
	// }
	.garybg {
		margin: 0 -0.3rem;
		background: #fafafa;
		padding-left: 0.3rem;
	}
	.item-con {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 1.12rem;
		
		.con {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-left: 0.3rem;
			span{
				flex: 1;
			}
		}
		.icon {
			width: 0.6rem;
			min-width: 0.6rem;
			height: 0.6rem;
			border-radius: 0.08rem;
			overflow: hidden;
			margin-right: 0.34rem;
			
			&-1 {
				width: 0.96rem;
				height: 0.76rem;
				
			}
			&-2 {
				width: 0.72rem;
				height: 0.72rem;
			}
			&-3 {
				width: 0.6rem;
				height: 0.6rem;
			}
			&-4 {
				width: 0.48rem;
				height: 0.48rem;
			}
			&-5 {
				width: 0.38rem;
				height: 0.38rem;
			}
		}
		.navtype {
			width: 22px;
			height: 22px;
		}
		.arrow {
			opacity: 0.5;
			color: #333334;
			transition: all 0.3s ease-in-out;
		}
	}
	.hasIcon {
		height: auto;
	}
	.hasChild {
		.arrow {
			transform: rotate(90deg);
		}
	}
	.showChild {
		.arrow {
			transform: rotate(-90deg);
		}
	}
	// .hasChild + .menu-item {
	// height: 0;
	// overflow: hidden;
	// }
	.strongText {
		// font-weight: 500;
		font-size: 0.28rem;
		color: #323233;
		padding-right: 0.3rem;
	}
}
.nav-type-1 {
	.garybg {
		border-top: 0.15rem #fafafa solid;
		background: #fff;
	}
	.item-con {
		.con {
			.icon {
				&-1 {
					width: 0.84rem;
					height: 0.84rem;
				}
				&-2 {
					width: 0.72rem;
					height: 0.72rem;
				}
			}
			
		}
	}
}
.hideChild {
	height: 0;
	overflow: hidden;
}
.showChild + .menu-item {
	background: #fafafa;
	// margin: 0 0.3rem;
}
.item-box.active{
	background-color: #05549A !important;
	color: #fafafa!important;
}
.item-box.active .strongText{
	// background-color: #05549A !important;
	color: #fafafa;
}
.item-box.active .garybg .strongText{
	color: #232323;
}
</style>

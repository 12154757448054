<template>
	<div class="page-box">
		<div class="nav-bar">
			<div class="center">
				<slot>{{ title }}</slot>
			</div>
			<div class="right">
				<span
					style="color: #05549a; font-size: 0.4rem"
					class="menu-icon iconfont icon-mulu"
					@click="show = true"
				></span>
			</div>
		</div>
		<div class="videosBox">
			<loadingBox v-if="isLoading"></loadingBox>
			<CoustmEmpty v-if="isEmpty"></CoustmEmpty>
			<vidoeList2  :vidoeData="vidoeData"></vidoeList2>
			<div class="paly" v-if="isPaly">该科目暂未激活</div>
		</div>
		<van-popup
			v-model="show"
			position="right"
			:style="{ width: '80%', height: '100%' }"
			:lock-scroll="false"
			closeable
		>
			<popupMenu :menuData="menuData" :title="documentTitle"></popupMenu>
		</van-popup>
		<!-- <loadingBox class="loading2" v-else></loadingBox> -->
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import LoadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
import popupMenu from './components/popupMenu.vue';
import BetterScroll from 'better-scroll';
import ScrollBar from '@better-scroll/scroll-bar';
BetterScroll.use(ScrollBar);
export default {
	name: 'specialSubject',
	components: {
		LoadingBox,
		CoustmEmpty,
		popupMenu,
		vidoeList2: () => import('./components/videoList2.vue')
	},
	computed: {
		scrollXwidth: function () {
			return 2.38 * this.classData.length;
		}
	},
	watch: {},
	data() {
		return {
			classData: [],
			menuData: [],
			vidoeData: [],
			show: false,
			isLoading: false,
			isEmpty: false,
			title: '请选择专题和类目',
			menuActive:[],
			menuLayer:0,
			titleC:'',
			documentTitle:this.$route.query.name+'列表'||'',
			isPaly:false,
		};
	},
	
	async created() {
		window.document.title = this.$route.query.name+'专题课';
		await this.initClass();
	},
	mounted() {
		this.$eventBus.$on('menuActive', (val) => {
			
			this.menuLayer = val.menuLayer;
			this.menuActive = val.children;
			this.titleC = val.name
		});
		this.$eventBus.$on('getShowMenu', (val) => {
			if(this.menuLayer<2){
				return 
			}
			this.show = false;
			this.showMenu();
			this.title = this.titleC
		});
	},
	methods: {
		setSourceUrl,
		initClass() {
			this.$api
				.getCourseList({
					role_id: 2,
					subject_id: this.$route.query.subject_id
				})
				.then((res) => {
					let resData = res.data || [];
					if (res.code == 1) {
						this.isPaly = true;
					}
					resData.forEach((item, index) => {
						let idx = 0;
						item.index = idx;
						if (index == 0) {
							item.showChild = true;
						} else {
							item.showChild = false;
						}
						this.forEachData(item, idx);
					});
					this.menuData = resData;
					if(resData.length==0){
						return false;
					}
					this.menuActive = resData[0].children[0].children[0].children ;
					this.title = resData[0].children[0].children[0].name;
					this.showMenu()
				});
		},
		showMenu(resData) {
			this.isLoading = true;
			if(!Array.isArray(this.menuActive)){return false;}
			  this.$api
			    .getMenuContent({
			      course_id: this.menuActive.map((item) => item.id),
			    })
			    .then((res) => {
					this.vidoeData = res.data;
					console.log(this.vidoeData)
					this.isEmpty = res.data.length ==0?true:false;
					this.isLoading = false;
			    });
		},
		forEachData(arr, idx, url, name) {
			if (arr.children) {
				++idx;
				arr.children.forEach((item1) => {
					item1.index = idx;
					item1.showChild = false;
					item1.active = false;
					item1.url = item1.url ? item1.url : url;
					item1.name = item1.name ? item1.name : name;
					if (item1.children) {
						this.forEachData(item1, idx, item1.url ? item1.url : url, item1.name ? item1.name : name);
					}
				});
			}
		}
	
	}
};
</script>

<style lang="scss" scoped>
.page-box {
	width: 100%;
	height: 100%;
	padding: 0 0.24rem;
	position: relative;
	
}
.nav-bar {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 0.88rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.24rem;
	.center {
		font-size: 0.32rem;
	}
	.right {
		display: flex;
		align-items: center;
	}
}
.videosBox {
	margin-top: 0.88rem;
	height: calc(100% - 0.88rem);
	overflow: auto;
	position: relative;
}
.paly {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 5;
}
</style>

const state = {
  webInfs: {},
  refreshToken: false,
  siteurl: "",
  pageIds: {},
  pageData: {},
  subNav: [],
  currentSuject: {},
  answerStatus: [],
  questionObject: [],
  bookContent: [],
  currentQuestion: 0,
  totalQuestion: 0,
  wordsList: [],
  lianxiData1: [],
  lianxiData2: [],
  unitNav: [],
  //gz
  userInfs:{}
};
const getters = {
  getWebInfs(state) {
    return state.webInfs;
  },
  getRefreshToken(state) {
    return state.refreshToken;
  },
  getSiteurl(state) {
    return state.siteurl;
  },
  getPageIds(state) {
    return state.pageIds;
  },
  getPageData(state) {
    return state.pageData;
  },
  getSubNav(state) {
    return state.subNav;
  },
  getCurrentSuject(state) {
    return state.currentSuject;
  },
  getAnswerStatus(state) {
    return state.answerStatus;
  },
  getQuestionObject(state) {
    return state.questionObject;
  },
  getBookContent(state) {
    return state.bookContent;
  },
  getCurrentQuestion(state) {
    return state.currentQuestion;
  },
  getTotalQuestion(state) {
    return state.totalQuestion;
  },
  getWordsList(state) {
    return state.wordsList;
  },
  getLianxiData1(state) {
    return state.lianxiData1;
  },
  getLianxiData2(state) {
    return state.lianxiData2;
  },
  getUnitNav(state) {
    return state.unitNav;
  },
  //   gz
  getUserInfs(state) {
    return state.userInfs;
  },
};
const actions = {};
const mutations = {
  setWebInfs(state, data) {
    state.webInfs = data;
  },
  setRefreshToken(state, data) {
    state.refreshToken = data;
  },
  setSiteurl(state, data) {
    state.siteurl = data;
  },
  setPageIds(state, data) {
    state.pageIds[data.name] = data.id;
  },
  setPageData(state, data) {
    state.pageData[data.name] = data.data;
  },
  setSubNav(state, data) {
    state.subNav = data;
  },
  setCurrentSuject(state, data) {
    state.currentSuject = data || {};
  },
  setAnswerStatus(state, data) {
    state.answerStatus = data;
  },
  setQuestionObject(state, data) {
    state.questionObject = data;
  },
  setBookContent(state, data) {
    state.bookContent = data;
  },
  setCurrentQuestion(state, data) {
    state.currentQuestion = data;
  },
  setTotalQuestion(state, data) {
    state.totalQuestion = data;
  },
  setWordsList(state, data) {
    state.wordsList = data;
  },
  setLianxiData1(state, data) {
    state.lianxiData1 = data;
  },
  setLianxiData2(state, data) {
    state.lianxiData2 = data;
  },
  setUnitNav(state, data) {
    state.unitNav = data;
  },
  //   gz
  setUserInfs(state, data) {
    state.userInfs = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
<div class="inner-page">
<navBar>
<span class="title">{{pageTitle}}</span>
</navBar>
<div class="page-content">
<iframe class="web-view" :src="webUrl" frameborder="0"></iframe>
</div>
</div>
</template>

<script>
export default {
name: "webView",
data() {
return {
pageTitle:"",
webUrl: "",
};
},
created() {
this.webUrl = this.$route.query.webUrl;
this.pageTitle = this.$route.query.title;

},
methods: {},
};
</script>

<style lang="scss" scoped>
.web-view {
width: 100vw;
height: 100vh;
}
</style>
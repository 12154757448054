<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">学习记录</span>
		</navBar>
		<div class="page-box hasnav notab haspadding">
			<loading-box v-if="isLoading"></loading-box>
			<template v-else>
				<div class="page-content" v-if="!isEmpty">
					<courseList :courseData="courseData" v-if="courseData.length"></courseList>
				</div>
				<coustm-empty v-else></coustm-empty>
			</template>
		</div>
	</div>
</template>

<script>
import courseList from '@/components/courseList.vue';
import LoadingBox from '@/components/loadingBox.vue';
export default {
	name: 'moreHistory',
	components: {
		courseList,
		LoadingBox
	},
	data() {
		return {
			isEmpty: false,
			isLoading: false,
			courseData: []
		};
	},
	created() {
		this.getLastLearn();
	},
	mounted() {},
	methods: {
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: this.$route.query.active
				}
			});
		},
		getLastLearn() {
			let that = this;
			that.isLoading = true;
			that.$api
				.getLearnHistory({
					type: 1
				})
				.then((res) => {
					let resData = res.data || [];
					that.courseData = resData;
					that.isLoading = false;
					if (!resData.length) {
						this.isEmpty = true;
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.page-box {
	background: #fff;
}
.haspadding {
	padding: 15px;
}
</style>

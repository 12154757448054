<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">版本信息</span>
		</navBar>
		<div class="page-box hasnav">
			<loading-box v-if="isLoading"></loading-box>
			<template v-else>
				<van-tabs
					v-model="activeCourse"
					v-if="courseData.length"
					class="version-tab"
					:swipeable="true"
					@change="changeTab"
				>
					<van-tab v-for="(course, index) in courseData" :title="course.name" :key="`tab${index}`">
						<ul class="version-list">
							<li
								v-for="(item, idx) in versionData"
								:key="`version${idx}`"
								:class="{
									active: selectedData[currentCourse].includes(item.id) ? true : false
								}"
								@click="selectVersion(item.id)"
							>
								<span class="suctomCheck">
									<van-icon class="success" name="success" />
								</span>
								{{ item.name }}
							</li>
						</ul>
					</van-tab>
				</van-tabs>
				<p class="notify" v-if="versionData.length">每科最多选2个版本</p>
				<div class="save-btn" @click="saveVersion">保存版本</div>
			</template>
		</div>
	</div>
</template>

<script>
import loadingBox from '@/components/loadingBox.vue';
export default {
	components: { loadingBox },
	name: 'versionPage',
	data() {
		return {
			isLoading: false,
			currentCourse: '',
			activeCourse: 0,
			courseData: [],
			versionData: [],
			requestAcount: 0,
			selectedData: {}
		};
	},
	created() {
		this.initData();
	},
	mounted() {},
	methods: {
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: 2
				}
			});
		},
		changeTab(val) {
			this.currentCourse = this.courseData[val].id;
		},
		initData() {
			this.getCourse();
			this.getPress();
		},

		getPress() {
			this.loadingPress = true;
			this.isLoading = true;
			this.$api.pressList().then((res) => {
				this.loadingPress = false;
				this.versionData = res.data || [];
				this.isLoading = false;
			});
		},
		getCourse() {
			this.loadingCourse = true;
			this.isLoading = true;
			this.$api.getSubject().then((res) => {
				this.loadingCourse = false;
				this.courseData = res.data || [];
				this.activeCourse = res.data[0].name;
				this.currentCourse = this.courseData[0].id;
				for (let i = 0; i < this.courseData.length; i++) {
					this.$set(this.selectedData, this.courseData[i].id, []);
				}
				for (let i = 0; i < this.courseData.length; i++) {
					this.subjectPress(this.courseData[i].id);
				}
				this.isLoading = false;
			});
		},
		subjectPress(id) {
			this.loadingSelect = true;
			this.$api
				.selectPress({
					subject_id: id
				})
				.then((res) => {
					this.loadingSelect = false;
					let resData = res.data || [];
					let _select = [];
					_select = resData[0]?.pressid || [];
					for (let i in _select) {
						_select[i] = Number(_select[i]);
					}
					this.$set(this.selectedData, id, _select);
				});
		},

		computeChecked(str, n) {
			let arr = str.split(',');
			if (arr.includes(String(n))) {
				return true;
			} else {
				return false;
			}
		},
		selectVersion(id) {
			let ids = this.selectedData[this.currentCourse] || [];

			if (ids.includes(id)) {
				let idx = ids.indexOf(id);
				ids.splice(idx, 1);
			} else {
				ids.push(id);
			}
			if (2 < ids.length) {
				ids.splice(0, 1);
			}
			this.$set(this.selectedData, this.currentCourse, ids);
		},
		saveVersion() {
			this.$toast({
				message: '保存中...',
				type: 'loading'
			});
			this.$api
				.saveCouresPress({
					subject_id: this.currentCourse,
					pressid: this.selectedData[this.currentCourse].join()
				})
				.then(() => {
					this.$toast({
						message: '保存成功',
						type: 'success'
					});
				});
		}
	}
};
</script>

<style lang="scss">
.version-tab {
	.van-tabs__line {
		background-color: #36ba9e;
	}
}
</style>
<style lang="scss" scoped>
.notify {
	color: #ff3232;
	line-height: 0.5rem;
	text-align: center;
	background: #fff;
	font-size: 0.24rem;
}
.save-btn {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: 0.9rem;
	background: #28b295;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.32rem;
	color: #fff;
	&:active {
		opacity: 0.8;
	}
}
.version-list {
	height: calc(100vh - 180px);
	margin-top: 3px;
	padding-bottom: 0.9rem;
	overflow-y: auto;
	li {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 0.88rem;
		padding: 0 0.32rem;
		border-bottom: 1px #dcdcdc solid;
		font-size: 0.28rem;
		font-weight: 500;
		color: #333;
		line-height: 0.32rem;
		cursor: pointer;
		&:last-child {
			border: 0;
		}
		.suctomCheck {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 0.24rem;
			height: 0.24rem;
			background: #ffffff;
			border: 1px solid #dcdcdc;
			margin-right: 16px;
			.success {
				opacity: 0;
			}
		}
	}
	.active {
		color: #28b295;
		.suctomCheck {
			border-color: #28b295;
			.success {
				transition: all 0.3s ease-in-out;
				opacity: 1;
			}
		}
	}
}
</style>

import http from "../http";

const user = {
  //获取科目接口
  getSubject: function (params) {
    return http.get("/api/v2/subject", params);
  },

  //获取历史记录接口
  getHistory: function (params) {
    return http.get("/api/v2/learningrecord", params);
  },
  //获取广告图
  getSwiperlist: function (params) {
    return http.get("/api/v2/banner", params);
  },
  //获取消息
  getIndexNotify: function (params) {
    return http.get("/api/v2/news", params);
  },
  
};

export default user;

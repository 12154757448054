<template>
	<div class="page-box">
		<div v-if="dataList" v-html="dataList"></div>
		<coustmEmpty v-else></coustmEmpty>
	</div>
</template>

<script>

import { setSourceUrl } from '@/utils/public';

export default {
	name: 'mainPage',
	components: {
		
	},
	computed: {		
		
	},
	watch: {		
	},
	data() {
		return {
			dataList:''
		};
	},
	async created() {
		this.dataList = this.$store.getters["common/getWordsList"];
		window.document.title = this.$route.query.name||'讲义';
	},
	mounted() {
		
	},
	destroyed() {
		// this.$store.commit("common/setWordsList",'');
	},
	methods: {
		setSourceUrl
	}
};
</script>

<style lang="scss" scoped>
	.page-box{
		width: 100%;
		height: 100%;
		padding:  0.24rem;
		img{height: auto;}
	}
	
</style>

<template>
	<div class="loading-box">
		<div>
			<!-- <i class="el-icon-loading"></i> -->
			数据加载中...
		</div>
	</div>
</template>

<script>
import { Toast } from "vant";
export default {
	name: 'loadingBox',
	mounted() {

	}
};
</script>

<style lang="scss" scoped>
.loading-box {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	font-size: 0.24rem;
	i {
		font-size: 0.4rem;
		color: #28b295;
	}
}
</style>
